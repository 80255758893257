.searchbar:focus{
    background-color: transparent;
    box-shadow: none !important;
    color: white;
}

input::placeholder{
    color: #AFAFAF !important;
}

.table>:not(caption)>*>* {
    background-color: transparent !important;
    border-bottom-width: none !important;
    box-shadow: none !important;
}

tbody tr {
border-color: transparent !important; 
    border-style: none !important; 
    border-width: 0 !important;
}

tbody tr:hover{
    background: #312f2f6c;
}

.row-actions{
    display: flex;
    align-items: center;
    padding-top: 0.8rem !important;
}

.row-last{
    padding-top: 0.8rem !important;
}

.navbar-icon{
    cursor: pointer;
}

.chat-list{
    border-right: 1px solid rgba(255, 255, 255, 0.197);
    padding-right: 1rem;
}

.chat-subject{
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.selected-chat{
    background-color: rgba(0, 194, 236, 0.18);
}

.chat-container{
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 12px;
    cursor: pointer;
}

.chat-updated-at{
    font-size: 14px;
    font-weight: 300;
    color: #fff;
}

.message-input{
    display: flex;
    align-items: center;
}

.send-button{
    background: transparent !important;
    box-shadow: none;
}

.messages-container::-webkit-scrollbar {
    width: 7px; 
    background-color: transparent; 
    border-radius: 100px; 
}

.messages-container::-webkit-scrollbar-thumb {
    background-color: #131313;
    border-radius: 100px; 
}

.messages-container {
    scrollbar-width: thin; 
    scrollbar-color: #131313 transparent; 
}

.message-from {
    margin-left: auto;
    background-color: rgba(0, 194, 236, 0.18) !important;
    margin-right: 0.5rem;
}

.message-time{
    color: #d5d5d5;
    font-weight: 300;
    font-size: 12px;
    line-height: 12px;
    margin-top: 0.4rem;
    margin-bottom: 0.4rem !important;
    text-align: right;    
}

.unread-container{
    display: none;
}

.unread-true{
    border-radius: 100px;
    width: 8px;
    height: 8px;
    background-color: red;
    display: inline-table !important;
    margin-left: 0.5rem;
}

/*Desktop*/
@media (min-width: 1025px) {

    .w-30-2{
        max-width: 30% !important;
    }
    
    
    .w-70-2{
        max-width: 70% !important;
    }

    .messages-container{
        height: 80vh;
        overflow-y: scroll;
    }

    .message{
        color: #fff;
        width: 40%;
        border-radius: 20px;
        background-color: #2E2E2E;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 14px;
        margin-bottom: 1rem;
    }

    .dashboard-component{
        width: 84vw;
        margin-left: auto;
        background: #222222;
        padding-top: 5rem;
        padding-left: 2rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        height: 100vh;
    }

    .searchbar{
        margin-bottom: 0.5rem !important;
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        width: 100% !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .reply-input{
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        width: 100% !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .button-status-started{
        background-color: #04ec0034  !important;
        color: #24FF00 !important;
    }

    .button-status-pending{
        background-color: #eeff0034  !important;
        color: #e5ff00 !important;
    }

    .button-status-completed{
        background-color: #02a8f534  !important;
        color: #0d6efd !important;
    }

    .chat-list{
        width: 100%;
        height: 89vh;
        overflow-y: auto;
    }
}

/*Mobile*/
@media (max-width: 767px) {

    .dashboard-component{
        width: 100vw;
        margin-left: auto;
        background: #222222;
        padding-top: 5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        height: 100vh;
    }

    .messages-container{
        height: 50vh;
        overflow-y: scroll;
    }

    .searchbar{
        margin-bottom: 2rem !important;
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        width: 100% !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .reply-input{
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        width: 100% !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .table>:not(caption)>*>* {
        font-size: 12px;
    }

    .button-status-completed{
        color: #24FF00 !important;
        font-size: 10px !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        padding-top: 0.3rem !important;
        padding-bottom: 0.3rem !important;
        line-height: 0px;
        background-color: #04ec0034  !important;
    }

    .message-input {
        margin-top: 1rem;
    }

    .message{
        color: #fff;
        width: 90%;
        border-radius: 20px;
        background-color: #2E2E2E;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 14px;
        margin-bottom: 1rem;
    }

    .chat-list {
        overflow-y: scroll;
        height: 20vh;
        margin-bottom: 1rem;
        border-bottom: 1px solid rgba(255, 255, 255, 0.197) !important;
        border-right: none;
    }

}