.searchbar:focus{
    background-color: transparent;
    box-shadow: none !important;
    color: white;
}

input::placeholder{
    color: #AFAFAF !important;
}

.table>:not(caption)>*>* {
    background-color: transparent !important;
    border-bottom-width: none !important;
    box-shadow: none !important;
}

tbody tr {
border-color: transparent !important; 
    border-style: none !important; 
    border-width: 0 !important;
}

tbody tr:hover{
    background: #312f2f6c;
}

.row-actions{
    display: flex;
    align-items: center;
    padding-top: 0.8rem !important;
}

.row-last{
    padding-top: 0.8rem !important;
}

.navbar-icon{
    cursor: pointer;
}

.campaign-row{
    cursor: pointer;
}

/*Desktop*/
@media (min-width: 1025px) {
    .dashboard-component{
        width: 84vw;
        margin-left: auto;
        background: #222222;
        padding-top: 5rem;
        padding-left: 1.3rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        height: 100vh;
    }

    .searchbar{
        margin-bottom: 2rem !important;
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        width: 40% !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .button-status-started{
        background-color: #04ec0034  !important;
        color: #24FF00 !important;
    }

    .button-status-pending{
        background-color: #eeff0034  !important;
        color: #e5ff00 !important;
    }

    .button-status-completed{
        background-color: #02a8f534  !important;
        color: #0d6efd !important;
    }
}

/*Mobile*/
@media (max-width: 767px) {
    .dashboard-component{
        width: 100vw;
        margin-left: auto;
        background: #222222;
        padding-top: 5rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        height: 100vh;
    }

    .searchbar{
        margin-bottom: 2rem !important;
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        width: 100% !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .table>:not(caption)>*>* {
        font-size: 12px;
    }

    .button-status-completed{
        color: #24FF00 !important;
        font-size: 10px !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        padding-top: 0.3rem !important;
        padding-bottom: 0.3rem !important;
        line-height: 0px;
        background-color: #04ec0034  !important;
    }

    .button-status-started{
        background-color: #04ec0034  !important;
        color: #24FF00 !important;
    }

    .button-status-pending{
        background-color: #eeff0034  !important;
        color: #e5ff00 !important;
    }

    .button-status-completed{
        background-color: #02a8f534  !important;
        color: #0d6efd !important;
    }

}