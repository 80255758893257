h2{
    color: #fff !important;
    font-size: 24px !important;
    font-weight: 700 !important;
}

h3{
    color: #fff !important;
    font-size: 18px !important;
    font-weight: 700 !important;
}

.div-total-amount{
    margin-bottom: 2rem;
}

.total-amount{
    font-weight: 400;
}

.step-container h3{
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.labels-container h4{
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 400 !important;
}

.labels-container .h6{
    font-size: 10px;
    font-weight: 700;
    color: #fff;
}

.step-container{
    margin-top: 1rem !important;
}

#payment-element{
    width: 100% !important;
}

.buildstep p{
    color: #7D8590;
    line-height: 130%;
    margin-top: 1rem;
    font-size: 14px;
}

.subtitle{
    color: #7D8590;
    line-height: 130%;
    font-size: 14px;
}

.buildstep1_paragraph li{
    color: #7D8590;
    width: 90%;
    line-height: 100%;
    margin-top: 1rem;
    font-size: 14px;
}

.buildstep1{
    margin-top: 1rem;
}

.buildstep1_paragraph{
    margin-top: 2rem;
}

.buildstep-container{
    margin-top: 1rem;
}

p{
    color: #7D8590;
    line-height: 70%;
    margin-top: 1rem;
    font-size: 14px;
}

.label-container{
    display: flex;
    align-items: baseline;
    margin-right: 4rem
}

.radio-checkbox{
    margin-right: 0.5rem;
}

.date-picker {
    position: relative;
}
  
  .date-picker .fa-calendar {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: white;
  }
  
  .input-field-time {
    padding-right: 30px;
  }

  .input-field-time::-webkit-calendar-picker-indicator {
    filter: invert(1);
    cursor: pointer;
  }  
  

.w-70{
    width: 70% !important;
}

.w-50{
    width: 50% !important;
}

.input-group{
    align-items: baseline !important;
}

.input-group-text{
    background: transparent !important;
    color: white !important;
    border: none !important;
    padding: 0;
    line-height: 0 !important;
    height: fit-content;
    margin-right: 0.5rem;
}

.payment-image{
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 12px;
    border: 1px solid #00C2EC;
    width: fit-content;
}

.laststep-container{
    text-align: center;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5rem;
}

.laststep-container p{
    line-height: 130% !important;
    color: #a7a7a7;
}

.options .checkbox-label{
    color: white;
    margin-bottom: 0.5rem;
    cursor: pointer;
}

.options .checkbox-label input{
    margin-right: 0.5rem;
    cursor: pointer;
}

.paynow-notes{
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.paynow-notes p{
    color: #b3b3b3;
    line-height: 20px;
    font-size: 12px;
    margin-bottom: 0.5rem !important;
}

.buttons-control-checkout{
    display: flex;
    align-items: center;
    margin-bottom: 2rem
}

.buttons-control-checkout .button-next-builder{
    margin-left: 0 !important;
}

.buttons-control-checkout .button-back-step-3{
    margin-left: auto;
}

/*Desktop*/
@media (min-width: 1025px) {
    .input-field-time{
        margin-bottom: 2rem !important;
        margin-right: 1rem;
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .input-field-text{
        margin-bottom: 2rem !important;
        margin-right: 1rem;
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .input-field-text-2{
        margin-bottom: 0.5rem !important;
        margin-right: 1rem;
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .right-col{
        margin-top: 1.5rem;
    }

    .labels-container{
        display: flex;
        margin-bottom: 1rem;
    }

    
    .input-field-counter{
        color: #fff;
        margin-left: 0.5rem;
    }
}

/*Mobile*/
@media (max-width: 767px) {
    .input-field-time{
        margin-bottom: 2rem !important;
        margin-right: 1rem;
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .input-field-text{
        margin-bottom: 2rem !important;
        margin-right: 1rem;
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .input-field-text-2{
        margin-bottom: 0.5rem !important;
        margin-right: 1rem;
        border: 1px solid #ffffff8a !important;
        background: transparent !important;
        border-radius: 8px !important;
        color: white !important;
        font-size: 12px !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        padding-left: 0.8rem !important;
        padding-right: 0.8rem !important;
    }

    .multi-select{
        margin-bottom: 1rem;
    }

    .budget-container{
        display: flex;
        align-items: baseline;
        margin-bottom: 1rem;
    }

    .labels-container{
        display: flex;
        margin-bottom: 0.5rem;
    }

    
    .input-field-counter{
        color: #fff;
        margin-left: auto;
    }
}