.home-container .first-section{
    padding-bottom: 5rem ;
}

.title span{
    background: #06f;
    background: linear-gradient(90deg,#00FFA3,#00B2FF);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;    
}

.subtitle h4{
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 14px;
    width: fit-content;
    background: #06f;
    background: linear-gradient(90deg,#00FFA3,#00B2FF);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;    
    border-width: 1px;
    border-style: solid;
    border-color: #00fff2;
    border-radius: 100px;
}

.subtitle h5{
    margin-top: 1rem;
    color: #fff;
    font-size: 22px;
}

.subtitle2 h4{
    padding-top: 0.3rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 14px;
    width: fit-content;
    background: #fff;
    color: #000;
    border-radius: 100px;
}

.btns-flex{
    margin-top: 1rem;
}

.btn-start {
    margin-right: 1rem;
}

.btn-start-timeline{
    margin-right: 1rem;
    margin-top: 3rem;
}

.service-card h5{
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-top: 1rem;
}

.service-card p{
    color: #7D8590;
    width: 90%;
    line-height: 140%;
    font-size: 16px;
    margin-bottom: 2rem;
}

.icon{
    background-color: #2D2D2D;
    border-radius: 20px;
    width: fit-content;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.btn-service{
    font-weight: 600;
    color: #fff !important;
    font-size: 16px !important;
    padding: 0 !important;
    background: transparent !important;
}

.timeline-section{
    margin-top: 2rem;
}

.timeline-step{
    display: flex;
}

.rounded-circle-object{
    width: 30px;
    height: 30px;
    background-color: #fff;
    border-radius: 100px;
}

.vertical-hr{
    height: 45vh;
    width: 5px;
    background: #7474BF;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, #1B1B1B, #00FFA3, #1B1B1B);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #1B1B1B, #00FFA3, #1B1B1B); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    margin-left: 0.8rem;
}

.timeline-text{
    padding-left: 3rem;
}

.timeline-text h3{
    color: #fff;
    font-size: 36px;
    font-weight: 700;
}

.timeline-text h5{
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.5px;
}

.timeline-text h5 span{
    background: #06f;
    background: linear-gradient(90deg,#00FFA3,#00FFA3);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-box-decoration-break: clone;    
}

.pricing-card{
    padding: 1.5rem;
    border-radius: 20px;
    border: 1px solid #555555;
    width: 33.33%;
    margin-top: 2rem;
}

.pricing-card h5{
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0 !important;
}

.pricing-card p{
    color: #7D8590;
    width: 90%;
    line-height: 140%;
    font-size: 16px;
    margin-bottom: 1.5rem;
}

.pricing-hr{
    height: 0.5px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    margin-bottom: 1rem;
}

.pricing-value{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.pricing-value .icon{
    background: transparent !important;
    padding: 0;
}

.pricing-value .icon svg{
    font-size: 20px !important;
    fill: #00fff2;
}

.pricing-value .text{
    color: #fff;
    font-size: 16px;
    margin-left: 1rem;
}

.pricing-amount{
    color: #fff;
    font-weight: 700;
    letter-spacing: -0.5px;
    font-size: 28px;
    margin-top: 2.5rem;
}

.btn-pricing{
    width: 100%;
    margin-top: 1rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    background-color: #fff !important;
    color: #000 !important;
}

.pricing-card-colored h5{
    color: #000;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0 !important;
}

.pricing-card-colored p{
    color: #000;
    width: 90%;
    line-height: 140%;
    font-size: 16px;
    margin-bottom: 1.5rem;
}

.pricing-card-colored .pricing-hr{
    height: 0.5px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2) !important;
    margin-bottom: 1rem;
}

.pricing-card-colored .pricing-value .icon svg{
    font-size: 20px !important;
    fill: #000;
}

.pricing-card-colored .pricing-value .text{
    color: #000;
    font-size: 16px;
    margin-left: 1rem;
}

.pricing-card-colored .pricing-amount{
    color: #000;
    font-weight: 700;
    letter-spacing: -0.5px;
    font-size: 28px;
    margin-top: 2.5rem;
}

.pricing-card-colored .btn-pricing{
    width: 100%;
    margin-top: 1rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    background-color: #000 !important;
    color: #fff !important;
}

.testimonial-card .icon{
    background: transparent;
    padding: 0;
}

.testimonial-card .icon svg{
    fill: #00FFA3;
}

.author{
    display: flex;
    align-items: center;
}

.author-name{
    color: #fff;
    font-size: 16px;
    font-weight: 700;
}

.author-role{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}

.author-image {
    width: fit-content;
    margin-right: 1rem;
}

.author-image img{
    width: 50px;
    height: 50px;
    border-radius: 100px;
    border: none !important;
    padding: 0 !important;
    background-color: transparent !important;
}

.questions-title{
    width: 100%;
    text-align: center;
    margin-bottom: 2.5rem;
}

.accordion-item{
    background: transparent !important;
    border: none !important;
}

.accordion-button{
    background-color: transparent !important;
    color: #fff !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    border-bottom: 1px solid #3A3A3A !important;
    border-radius: 0 !important;
}

.accordion-button:focus{
    box-shadow: none !important;
}

.accordion-button:not(.collapsed){
    box-shadow: none !important;
}

.accordion-button::after {
    display: none !important;
}

.accordion-body{
    font-size: 16px !important;
    color: #7D8590 !important;
    line-height: 140% !important;
}

.accordion-button .icon{
    background-color: transparent !important;
    padding: 0 !important;
    margin-right: 1rem;
}

.accordion-button .icon svg{
    font-size: 24px !important;
    fill: #00B2FF;
}

.accordion-button.collapsed .icon::before {
    content: "+";
    font-size: 40px;
    display: inline-block;
    margin-right: 5px; /* Aggiungi margine per spaziare il simbolo dal testo */
    transition: transform 0.3s ease; /* Aggiungi un'animazione di transizione */
    color: #00B2FF;
  }

  .accordion-button .icon::before {
    content: "-";
    font-size: 40px;
    display: inline-block;
    margin-right: 5px; /* Aggiungi margine per spaziare il simbolo dal testo */
    transition: transform 0.3s ease; /* Aggiungi un'animazione di transizione */
    color: #00B2FF;
  }

  .description span{
    color: #fff;
}

/*Desktop*/
@media (min-width: 1025px) {
    .home-container{
        padding-top: 10rem;
        padding-bottom: 5rem;
        padding-left: 5rem;
        padding-right: 5rem;
    }

    .home-section{
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    .first-section-left{
        margin-top: 4rem !important;
    }

    .service-card{
        padding: 1.5rem;
        border-radius: 20px;
        border: 1px solid #555555;
        width: 33.33%;
        margin-top: 2rem;
    }

    .title h1{
        font-size: 60px;
        font-weight: 700;
        line-height: 90%;
        color: #fff;
    }
    
    .title h3{
        font-size: 34px !important;
        font-weight: 700 !important;
        line-height: 90%;
        color: #fff !important;
    }

    .description{
        color: #7D8590;
        width: 90%;
        line-height: 130%;
        margin-top: 1rem;
        font-size: 16px;
    }

    .pricing-card{
        padding: 1.5rem;
        border-radius: 20px;
        border: 1px solid #555555;
        width: 33.33%;
        margin-top: 2rem;
    }

    .pricing-card-colored{
        padding: 1.5rem;
        border-radius: 20px;
        width: 33.33%;
        margin-top: 2rem;
        background: #7474BF;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #78F3E7, #83C5FE);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #78F3E7, #83C5FE); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    .testimonials-section{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch !important;
    }

    .testimonial-card {
        padding: 1.5rem;
        overflow-y: hidden;
        position: relative;
        border-radius: 20px;
        border: 1px solid #555555;
        margin-top: 2rem;
        flex: 1; /* Regola per tre card per riga, sottrai il gap */
    }
    
    .testimonial-card p{
        color: #fff;
        width: 90%;
        line-height: 140%;
        font-size: 16px;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
    }
}

/*Mobile*/
@media (max-width: 767px) {
    .first-section{
       display: inherit !important;
    }

    .first-section-left{
        margin-top: 2rem !important;
        margin-bottom: 3rem;
    }

    .home-container {
        padding-top: 6rem;
        padding-bottom: 5rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .home-section{
        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    .service-card{
        padding: 1.5rem;
        border-radius: 20px;
        border: 1px solid #555555;
        margin-top: 2rem;
    }

    .title h1{
        font-size: 56px;
        font-weight: 700;
        line-height: 100%;
        color: #fff;
    }

    .title h3{
        font-size: 34px !important;
        font-weight: 700 !important;
        line-height: 90% !important;
        color: #fff;
    }

    .description{
        color: #7D8590;
        width: 90%;
        line-height: 130%;
        margin-top: 1rem;
        font-size: 14px;
    }

    .build-your-campaing{
        margin-top: 3rem;
    }

    .timeline-text h3{
        color: #fff;
        font-size: 30px;
        font-weight: 700;
    }
    
    .timeline-text h5{
        color: #fff;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.5px;
    }

    .pricing-card{
        padding: 1.5rem;
        border-radius: 20px;
        border: 1px solid #555555;
        margin-top: 2rem;
        width: 100%;
    }

    .pricing-card-colored{
        padding: 1.5rem;
        border-radius: 20px;
        width: 100%;
        margin-top: 2rem;
        background: #7474BF;  /* fallback for old browsers */
        background: -webkit-linear-gradient(to right, #78F3E7, #83C5FE);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #78F3E7, #83C5FE); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    }

    .testimonials-section{
        width: 100%;
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
    }

    .testimonial-card{
        padding: 1.5rem;
        border-radius: 20px;
        border: 1px solid #555555;
        margin-top: 2rem;
        margin-right: 2rem;
        float: left;
    }

    .testimonial-card p{
        color: #fff;
        width: 60vw;
        line-height: 140%;
        font-size: 16px;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
    }

}