.campaign-title{
    font-size: 24px;
    font-weight: 400;
    color: white;
}

.campaign-status{
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.button-status{
    margin-left: 1rem;
    font-size: 12px !important;
}

.campaign-panel{
    margin-top: 1rem !important;
}

.data-panel-title{
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.data-panel-info{
    font-size: 14px;
    font-weight: 600;
    color: #fff;
}

.campaign-panel-information{
    margin-top: 2.5rem !important;
}

.information-title{
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.information-description{
    margin-top: 0.5rem;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
}

.progress-bar-container{
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
}

.counter-container{
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.counter{
    font-size: 16px;
    font-weight: 600;
    color: #fff;
}

.total-counter{
    margin-left: auto;
}

.campaign-attribute{
    display: flex;
    align-items: center;
}

.attribute-name{
    margin-left: 0.5rem;
    color: #fff;
}

.video-container{
    width: 100%;
    height: 40vh;
    background-color: #2E2E2E;
    margin-bottom: 1rem;
    border-radius: 12px;
}

/*Desktop*/
@media (min-width: 1025px) {
    .data-panel{
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        border-radius: 12px;
        background-color: #2E2E2E;
    }

    .campaign-link{
        padding-bottom: 2rem
    }
}

/*Mobile*/
@media (max-width: 767px) {

    .campaign-information{
        margin-bottom: 2rem !important;
    }
    
    .campaign-link{
        padding-bottom: 2rem
    }

    .button-start-campaign{
        width: fit-content !important;
    }

    .data-panel{
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        border-radius: 12px;
        background-color: #2E2E2E;
        margin-bottom: 1rem
    }
}